import React from 'react'
import styled from 'styled-components'
import { Box } from 'theme-ui'
import Seo from '../components/Seo'
import BlueElephant from "../images/logos/factcil_icon.png"


const TitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: bold;
`

const DotText = styled.span`
  color: ${({ theme }) => theme.colors.green};
  font-weight: bold;
`

const SubTitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
`

const ItemTitleText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: bold;
`

const ItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  font-weight: 400;
`

const ListItemText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 1rem;
  text-align: left;
  margin-left: 2rem;
  font-weight: 400;
`

const SignatureText = styled(Box)`
  color: ${({ theme }) => theme.colors.dark};
  margin-top: 0px;
  text-align: left;
`

const tosItems = [
  {
    type: 'SubTitleText',
    content: 'I.	PREGUNTAS FRECUENTES'
  },
  {
    type: 'ItemTitleText',
    content: '1.	¿Quiénes somos?'
  },
  {
    type: 'ItemText',
    content: 'FACTCIL es líder en el mercado para proveer servicios de back office y servicios financieros para trabajadores independientes y freelancers a nivel mundial.'
  },
  {
    type: 'ItemTitleText',
    content: '2.	¿Qué hacemos?'
  },
  {
    type: 'ItemText',
    content: ' Brindamos una plataforma tecnológica (En adelante La Plataforma) en donde a cambio de una contraprestación, como trabajador independiente puedes organizar y automatizar procesos de tu negocio o trabajo y solicitar con nosotros, el adelanto del pago de las cuentas de cobro que presentes a las diferentes empresas que te contraten, usando tecnología que nos permite tener información en tiempo real haciendo nuestros procesos más efectivos e incluyentes.'
  },
  {
    type: 'ItemTitleText',
    content: '3.	¿Qué es el back office?'
  },
  {
    type: 'ItemText',
    content: 'Son todas las tareas relacionadas con la gestión interna de tu negocio/trabajo, la cuál puedes automatizar y simplificar a través de la plataforma de Factcil. Adicional a este servicio, puedes contar con un apoyo de asistente virtual que se encargará de administrar tus cuentas de cobro.'
  },
  {
    type: 'ItemTitleText',
    content: '4.	¿Qué es el anticipo?'
  },
  {
    type: 'ItemText',
    content: <>Es la forma en que puedes adelantar el pago de tus servicios siempre y cuando ya lo hayas finalizado. Con Factcil puedes solicitar el adelanto de tu pago el cuál está sujeto a aprobación por parte nuestra y al cuál se le aplicará una tasa de descuento.<br /><br />
      La solicitud de anticipo que así lo indique, genera la obligación en cabeza del independiente de pagar el importe de la cuenta por cobrar en el evento en el cual la empresa que te contrató se niegue a realizar el pago de la cuenta por cobrar. No obstante, este riesgo está mitigado por las normas aplicables sobre la ejecución de facturas aceptadas.</>
  },
  {
    type: 'ItemTitleText',
    content: '5.	¿Cuáles son los requisitos para acceder a nuestra plataforma?'
  },
  {
    type: 'ItemText',
    content: 'Cuando comiences el proceso de registro, y según el tipo de servicio que quieras obtener de nosotros, podrás acceder a la lista de documentos requeridos y a las condiciones especiales de cada uno de estos productos.'
  },
  {
    type: 'ItemTitleText',
    content: '6.	¿Qué puedes hacer en nuestra plataforma?'
  },
  {
    type: 'ItemText',
    content: 'Mediante la plataforma, una vez te registres y pagues, podrás acceder a la siguiente información:'
  },
  {
    type: 'ListItemText',
    content: <>
      •	Administración de los servicios que prestas a las empresas;<br />
      •	Administración de tus clientes;<br />
      •	Generar cotizaciones de tus servicios;<br />
      •	Administración de tus cuentas de cobro o facturas;<br />
      •	Solicitud de anticipos de tus cuentas de cobro;<br />
      •	Servicio de asistente virtual;<br />
      •	Ingresos y gastos de tu negocio;<br />
      •	Pago y administración de tus planillas de seguridad social;<br />
      •	Recordatorios de cobranza;<br />
      •	Gestión de referidos para invitar a tus amigos;<br />
      •	visualización de documentos;<br />
      •	contacto para reclamos o consultas; y<br />
      •	notificaciones informativas.<br /></>
  },
  {
    type: 'ItemText',
    content: 'Adicionalmente, podrás:'
  },
  {
    type: 'ListItemText',
    content: <>
      •	actualizar tus datos de contacto;<br />
      •	suscribir documentos legales; <br /></>
  },
  {
    type: 'SubTitleText',
    content: 'II.	OTRAS CONDICIONES DE USO'
  },
  {
    type: 'ItemTitleText',
    content: '7.	Declaración:'
  },
  {
    type: 'ItemText',
    content: 'Cuando ingresas, navegas y usas el Sitio Web  www.factcil.com, (en adelante la Plataforma) como usuario aceptas inequívocamente que te has informado suficientemente sobre los términos y condiciones de uso de la misma y nuestra Política de Tratamiento de Datos, por lo tanto, te solicitamos ingresar y consultar estos términos y condiciones y la política de tratamiento de datos cada vez que vayas a utilizar la plataforma, a fin de mantenerte actualizado sobre las condiciones de uso de la misma.'
  },
  {
    type: 'ItemTitleText',
    content: '8.	Uso de la página web, sus contenidos y sus servicios:'
  },
  {
    type: 'ItemText',
    content: <>El ingreso a la página web constituye tu consentimiento como usuario a utilizarla, junto con sus servicios y contenidos, sin contravenir la legislación vigente, la buena fe, los usos generalmente aceptados y el orden público, la moral y las buenas costumbres. Asimismo, queda prohibido el uso de la página web con fines ilícitos o lesivos, contra Factcil o contra cualquier tercero que, de cualquier forma, puedan resultar perjudicados directamente o por impedir el normal funcionamiento de la página web.<br /><br />
      Los enlaces (links) a otras páginas o portales de Internet, distintas de aquellas de las cuales Factcil es propietaria, son ofrecidos como un servicio a ti como usuario. Factcil no estuvo involucrada en la producción y por lo tanto no es responsable por el contenido de dichas páginas de Internet</>
  },
  {
    type: 'ItemTitleText',
    content: '9.	Sobre la página web'
  },
  {
    type: 'ItemText',
    content: <>Para acceder a los servicios prestados por Factcil, deberás suscribir, según sea el caso, un contrato de factoring, (Link al contrato), un contrato de servicios administrativos, (Link al contrato), o el contrato que se defina por pate de Factcil para prestar un determinado servicio, el cual estará disponible al momento de solicitarlo.<br /><br />
      Una solicitud no implica que la misma sea aprobada por Factcil, toda vez que la aprobación de la solicitud parcial o total depende del resultado de diversos análisis realizados por parte de este directamente o por un tercero especializado a quien se le designe ese actividad.</>
  },
  {
    type: 'ItemTitleText',
    content: '10.	Usuario y Contraseña'
  },
  {
    type: 'ItemText',
    content: <>Cada una de las cuentas registradas en la página web tendrá tanto un usuario que constituye tu identificación en el sistema como una clave de ingreso. Al registrarte serás el responsable por la confidencialidad y el uso que se haga de tu usuario y/o contraseña.<br />
      Te comprometes a informar inmediatamente a Factcil sobre cualquier actividad anormal, uso no autorizado de tu usuario y/o contraseña, correo electrónico, o número de identificación.<br />
      De igual forma para tu registro como Usuario te comprometes a proveer a Factcil de información precisa y completa, así como a informarle sobre cualquier cambio o variación.<br />
      El usuario y/o contraseña deben ser utilizados únicamente por ti, por lo cual Factcil te solicita no compartir esta información con terceras personas.<br />
      De igual forma te obligas a abstenerte de compartir o transferir Tu cuenta a otra persona.</>
  },
  {
    type: 'ItemTitleText',
    content: '11.	Información y sitios web de terceros'
  },
  {
    type: 'ItemText',
    content: <>La página web puede ofrecer hipervínculos o acceso a páginas web y contenidos de otras personas o entidades.<br />
      Factcil no controla, refrenda ni garantiza el contenido incluido en dichos sitios, tampoco se responsabiliza del funcionamiento o accesibilidad de las páginas web vinculadas, ni sugiere o invita la visita a las mismas, por lo que tampoco será responsable del resultado obtenido.<br /><br />
      Como El usuario acepta que Factcil no es responsable de ningún contenido, enlace asociado o servicio relacionado con el sitio de un tercero. Así mismo, el Usuario acepta que Factcil no será responsable de ninguna pérdida o daño de cualquier tipo que se derive del uso que el usuario realice de los contenidos de un tercero.<br /><br />
      El servicio de liquidación de la Planilla para el pago de seguridad se hace a través de un proveedor que es ajeno a FACTCIL y que se ha puesto a disposición del usuario para realizar la mencionada actividad bajo su cuenta y riesgo. Cualquier error al momento del diligenciamiento de la planilla y cualquier sanción o perjuicio que se cause con ocasión a ello será responsabilidad exclusiva del proveedor del servicio de diligenciamiento de la planilla a quien el usuario deberá acudir para las reclamaciones respectivas, liberando a FACTCIL. De cualquier responsabilidad en ese sentido.</>
  },
  {
    type: 'ItemTitleText',
    content: '12.	Devoluciones de dinero'
  },
  {
    type: 'ItemText',
    content: <>Con relación al dinero que FACTCIL le desembolse a un cliente, por regla general no puede ser devuelto a menos que se cancelen los costos en que Factcil haya incurrido para el mencionado desembolso <i>y se cancele el valor determinado por honorarios generados hasta la fecha de la devolución.</i><br /><br />
      Una vez el cliente haya pagado algún servicio de factcil y éste haya realizado la gestión necesaria para cumplir con el servicio, no se harán devoluciones de dinero.
    </>
  },
  {
    type: 'ItemTitleText',
    content: '13.	Seguridad en internet'
  },
  {
    type: 'ItemText',
    content: <>Factcil no controla ni garantiza, y por lo tanto no se hace responsable por la presencia de virus ni de otros elementos en los contenidos de la página web y que hagan su aparición a pesar del cumplimiento de sus deberes y debida diligencia, de manera que puedan producir alteraciones en el sistema informático (software y hardware) del Usuario o en los documentos electrónicos y ficheros almacenados en el sistema informático del Usuario.<br />
      Se entiende por código malicioso el nombre que se le da a cualquier programa que ingresa a un computador sin el conocimiento y la autorización explícita del responsable del mismo, dentro de los cuales se encuentran, los denominados VIRUS; TROYANOS; GUSANOS; PHISHING; PHARMING; ROOTKITS; BACKDOOR (PUERTAS TRASERAS), KEYLOGGERS (CAPTURADORES DE TECLADO), SCREEN LOGGERS, BOTNETS, SNIFFERS (HUSMEADORES DE TRÁFICO DE LA RED), entre otros.<br /><br />
      Teniendo en consideración la anterior definición, te obligas incondicional e irrevocablemente a garantizar que la información y documentación (incluidas imágenes, fotografías, animación, vídeo, audio, música, texto y otros elementos que forman parte del portal) que entregues a Factcil, se encuentran libres de Código Malicioso. Así mismo, te comprometes a tomar las medidas necesarias para efectuar el control del Código Malicioso.
    </>
  },
  {
    type: 'ItemTitleText',
    content: '14.	Prohibición especial al cliente:'
  },
  {
    type: 'ItemText',
    content: 'Una vez el cliente solicite el servicio de anticipo de sus cuentas de cobro con factcil, se compromete a no gestionar por cuenta propia la radicación de sus documentos frente a la empresa que lo contrató. ver si se puede poner una multa. igual considero importante poner esta cláusula en el contrato de factoring.'
  },
  {
    type: 'ItemTitleText',
    content: '15.	Pasarelas de pago y facturación: '
  },
  {
    type: 'ItemText',
    content: 'Los Usuarios son plenamente conscientes y aceptan el uso de los sistemas electrónicos de pago, de los portales con los cuales la Plataforma ha hecho alianza (en adelante “Pasarelas de Pago”) para el pago de los servicios adquiridos a FACTCIL. Los Usuarios podrán encontrar los Términos y Condiciones de uso de cada Pasarela de Pago en sus respectivas páginas web, los cuales se comprometen a leer antes de realizar cualquier operación dentro de la Plataforma. Una vez completada la transacción a través de la plataforma, se enviará una factura al correo electrónico cliente por el valor de los servicios adquiridos.'
  },
  {
    type: 'ItemTitleText',
    content: '16.	Propiedad intelectual'
  },
  {
    type: 'ItemText',
    content: 'El Usuario acepta y reconoce que toda la información contenida en la plataforma, incluyendo pero no limitado al texto, los gráficos, el software, las marcas, la interfaz visual, los lemas comerciales, las fotografías y en general todo el contenido de la plataforma, son de propiedad de Factcil, o ha sido debidamente autorizado por sus titulares para hacer uso de aquellos.'
  },
  {
    type: 'ItemTitleText',
    content: '17.	Aviso de privacidad'
  },
  {
    type: 'ItemText',
    content: <>Para utilizar algunos de los servicios que ofrece esta página es necesario proporcionar una serie de datos de datos personales que se tratarán en la forma descrita en nuestra Política de Tratamiento de Datos.<br /><br />
      De conformidad con lo dispuesto en la Ley Estatutaria 1581 de 2012 y su Decreto reglamentario 1377 de 2013, Factcil como responsable de la plataforma, informa que los datos personales recolectados mediante el uso de esta página se realizarán de acuerdo a lo señalado en nuestra Política de Tratamiento de Datos.<br /><br />
      Factcil podrá recolectar datos de forma directa e indirecta a través de cookies u otros medios electrónicos. <br /><br />
      Al proporcionarnos tus datos personales, entiendes y aceptas que estos serán utilizados para los siguientes fines:
    </>
  },
  {
    type: 'ListItemText',
    content: <>
      •	El envío de información comercial, invitaciones o atenciones de Factcil, promoción de productos y servicios tanto propios como de terceros, y demás comunicaciones necesarias para mantener comunicado y enterado al cliente mediante: llamada telefónica, mensaje de texto, correo electrónico, Facebook, Twitter, Instagram o cualquier red social de integración o mensajería instantánea, entre otros.<br />
      •	La realización de encuestas de cualquier índole.<br />
      •	Invitación a eventos corporativos de Factcil y sus aliados<br />
      •	Conocer su comportamiento financiero, comercial y crediticio y el cumplimiento de sus obligaciones legales.<br />
      •	Realizar todas las gestiones necesarias tendientes a confirmar y actualizar la información del cliente.<br />
      •	Establecer una relación contractual, así como mantener y terminar una relación contractual.<br />
      •	Conocer la ubicación y datos de contacto del cliente para efectos de notificaciones con fines de seguridad y ofrecimiento de beneficios y ofertas comerciales.<br />
      •	Prevenir el lavado de activos, la financiación del terrorismo, así como detectar el fraude, corrupción, y otras actividades ilegales.<br />
      •	Realizar, validar, autorizar o verificar transacciones, incluyendo, cuando sea requerido, la consulta y reproducción de datos sensibles tales como la huella digital, imagen o voz, entre otros.<br />
      •	Compartir la información con las autoridades o entidades públicas, cuando existan disposiciones legales, contractuales o consensuales que obliguen a hacerlo.<br />
      •	Compartir la información con inversionistas o fondeadores, afiliadas, empleados y agentes de Factcil, proveedores de herramientas contratadas por Factcil para la comunicación y marketing con sus consumidores y para llevar a cabo labores de tercerización de productos y en los demás casos previstos en la Ley.<br />
      •	Diligenciar los documentos necesarios para mantener y terminar una relación contractual.<br />

    </>
  },
  {
    type: 'ItemText',
    content: 'Recuerda que, como titular de los datos personales, tienes derecho a:'
  },
  {
    type: 'ListItemText',
    content: <>
      •	Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.<br />
      •	Conocer, actualizar y rectificar tu información frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté prohibido o no haya sido autorizado.<br />
      •	Solicitar prueba de la autorización otorgada.<br />
      •	Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la normatividad vigente.<br />
      •	Revocar la autorización y/o solicitar la supresión del dato, siempre que no exista un deber legal o contractual que impida eliminarlos.<br />
      •	Abstenerse de responder a las preguntas sobre datos sensibles.<br />
    </>
  },
  {
    type: 'ItemText',
    content: 'Factcil se comprometen a no utilizar tus datos personales para otras finalidades que no hayan sido establecidas en este documento y en la Política de Tratamiento de Datos.'
  },
  {
    type: 'ItemTitleText',
    content: '18.	Ausencia de vínculo societario y/o laboral'
  },
  {
    type: 'ItemText',
    content: 'Las declaraciones contenidas en estos Términos y Condiciones no generan ningún tipo de contrato de sociedad, mandato, franquicia, o relación laboral entre los Usuarios y la plataforma, siendo las partes totalmente independientes en el ejercicio de sus actividades.'
  },
  {
    type: 'ItemTitleText',
    content: '19.	Reformas y modificaciones'
  },
  {
    type: 'ItemText',
    content: <>Factcil se reserva el derecho de modificar en cualquier momento y a su sola discreción, los presentes Términos y Condiciones y/o los documentos que hagan parte integral de los mismos, así como a dar por terminado la prestación del servicio. Todas las modificaciones serán publicadas en la Plataforma. Así mismo, los usuarios registrados recibirán una notificación electrónica al correo registrado con una antelación no menor a diez (10) días de la entrada en vigencia de los cambios. En caso de no estar de acuerdo con dichas modificaciones, los usuarios registrados podrán comunicar su no aceptación vía correo electrónico a <b>latam-datospersonales@factcil.com.</b> En este caso en particular, una vez finalicen los servicios previamente adquiridos, quedará disuelto el vínculo generado entre la plataforma y el usuario y se procederá a inhabilitarlo como usuario registrado.<br /><br />
      Vencido el plazo señalado de los diez (10) días sin que la Plataforma haya recibido comunicación alguna frente a la no aceptación de la modificación por parte del Usuario, se considerará que has aceptado los nuevos Términos y Condiciones.<br /><br />
      Factcil se reserva el derecho de realizar, en cualquier momento y sin necesidad de preaviso, modificaciones o actualizaciones de sus contenidos y servicios en la plataforma, así como de los elementos que integren el diseño y configuración de la misma.<br /><br />
    </>
  },
  {
    type: 'ItemTitleText',
    content: '20.	Arreglo directo de conflictos. '
  },
  {
    type: 'ItemText',
    content: 'En caso de que surjan diferencias entre las partes por razón o con ocasión de los presentes Términos y Condiciones, serán resueltas por ellas mediante procedimientos de autocomposición directa, tales como negociación directa o mediación. Para tal efecto, las partes dispondrán de quince (15) días hábiles contados a partir de la fecha en que cualquiera de ellas requiera a la otra por escrito en tal sentido, término éste que podrá ser prorrogado de común acuerdo, vencido el mencionado plazo, las partes quedan en plena libertad de proceder a solucionar sus diferencias ante la justicia ordinaria.'
  },
  {
    type: 'ItemTitleText',
    content: '21.	Nulidad de alguna o algunas de las partes de los presentes términos y condiciones:'
  },
  {
    type: 'ItemText',
    content: 'Si alguna de las disposiciones recogidas en los presentes Términos y Condiciones careciera de validez o fuera declarada nula por una autoridad competente, se considerará sin efecto, no afectando la eficacia y validez del resto de disposiciones. Así, en caso de surgir alguna disputa, las Partes en ella se comprometen a presentar sus mejores esfuerzos para reemplazar dicha disposición nula por una disposición válida.'
  },
  {
    type: 'ItemTitleText',
    content: '22.	 Limitación de responsabilidad'
  },
  {
    type: 'ItemText',
    content: 'En ningún caso Factcil, sus administradores, empleados, socios, proveedores o proveedores de contenido serán responsables de daños directos, indirectos, fortuitos, derivados, o de ningún tipo, incluidos, aunque sin limitarse a ellos, los daños (i) resultantes de su acceso a los Servicios, o uso de los Servicios, o de la imposibilidad de acceder a los Servicios o usarlos; (ii) por lucro cesante, pérdida de datos o costes de adquisición o sustitución de bienes o servicios; o (iii) por cualquier conducta o contenido de terceros en la plataforma. En ningún caso la responsabilidad de Factcil  superará (en total) la cantidad de un (1) Salario Mínimo Legal Mensual Vigente para la República de Colombia'
  },
  {
    type: 'ItemTitleText',
    content: '23.	Impuestos: '
  },
  {
    type: 'ItemText',
    content: 'Los Usuarios de la plataforma, serán los únicos responsables por los impuestos y retenciones que se generen por las transferencias y operaciones que estos realicen a través de la Plataforma.'
  },
  {
    type: 'ItemTitleText',
    content: '24.	Forma de computar los plazos:'
  },
  {
    type: 'ItemText',
    content: 'Los plazos en días, meses o años establecidos en estos Términos y Condiciones o en desarrollo de los mismos, se entienden calendario, a menos que en el presente documento se disponga de manera expresa otra cosa.'
  },
  {
    type: 'ItemTitleText',
    content: '25.	 Idioma: '
  },
  {
    type: 'ItemText',
    content: 'Los presentes Términos y Condiciones han sido concebidos y redactados en idioma español, los cuales serán la versión oficial y válida para todos los efectos legales. Cualquier traducción de estos Términos y Condiciones a otro idioma tendrá efectos exclusivos de consulta.'
  },
  {
    type: 'ItemTitleText',
    content: '26.	 Ley aplicable: '
  },
  {
    type: 'ItemText',
    content: 'Los presentes Términos y Condiciones se regirán, interpretará y ejecutará íntegramente por leyes de la República de Colombia, en particular las disposiciones del derecho privado que resulten aplicables de acuerdo con el régimen jurídico aplicado a las sociedades por acciones simplificadas.'
  },
  {
    type: 'ItemTitleText',
    content: '27.	 Domicilio: '
  },
  {
    type: 'ItemText',
    content: 'Para todos los efectos, LAS PARTES acuerdan como domicilio contractual la ciudad de Bogotá.'
  },
  {
    type: 'ItemTitleText',
    content: '28.	Vigencia de los términos y condiciones'
  },
  {
    type: 'ItemText',
    content: <>
      Estos términos y condiciones estarán vigentes desde su publicación.<br /><br />
      Estos términos actualizados entraron en vigor el 17 de mayo de 2022, a las 12 a. m., hora de Colombia, y son de aplicación a partir de esa fecha.
    </>
  }
]

const TermsAndConditionsPage = () => (
  <React.Fragment>
    <Seo title='Términos y condiciones' />
    <Box
      sx={{
        textAlign: 'left',
        p: [15, 30],
        maxWidth: 1200,
        margin: '0 auto',
      }}
    >
      <TitleText as="h1" >
        Términos y condiciones de uso<DotText>.</DotText>
      </TitleText>
      {tosItems.map((item) => {
        if (item.type === 'SubTitleText') {
          return <>
            <SubTitleText as="h5" >
              {item.content}
            </SubTitleText></>
        }
        if (item.type === 'ItemTitleText') {
          return <>
            <ItemTitleText as="h5" >
              {item.content}
            </ItemTitleText></>
        }
        if (item.type === 'ItemText') {
          return <>
            <ItemText as="h6" >
              {item.content}
            </ItemText></>
        }
        if (item.type === 'ListItemText') {
          return <>
            <ListItemText as="h6" >
              {item.content}
            </ListItemText></>
        }
      })}
      <div className='mt-5 container'>
        <div className='row d-flex justify-content-between'>
          <div className='container p-0 d-flex justify-content-start col-sm-12 col-md-6'>
            <div className='row'>
              <div className='col-6 p-0'>
                {<img src={BlueElephant} alt="blue-elephant" style={{ maxWidth: '4rem' }} />}
              </div>
              <div className='col-6 p-0 align-self-center'>
                <div className='col-12 p-0'>
                  Atentamente
                </div>
                <div className='col-12 p-0 '>
                  <SignatureText as="h6" >
                    Factcil
                  </SignatureText>
                </div>
              </div>
            </div>
          </div>
          <div className='container p-0 d-flex justify-content-end col-sm-12 col-md-6'>
            <div className='row'>
              <div className='col-12 p-0' style={{ textAlign: 'end' }}>
                Última actualización
              </div>
              <div className='col-12 p-0'>
                <SignatureText as="h6" style={{ textAlign: 'end' }}>
                  17 de mayo de 2022
                </SignatureText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  </React.Fragment >
)

export default TermsAndConditionsPage
